<template>
  <v-container fluid>
    <CommonFilter
      @update-filter="filterData"
      :validations="validations"
      @clear-data="clearData"
      :filterList="filterList"
      :type="type"
    />
    <v-card class="mt-7 section-wrap">
      <v-data-table
        :headers="headersMain"
        :items="statisticsData"
        item-key="name"
        :items-per-page="200"
        item-class="item_group"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100, 200],
          'items-per-page-text': $t('labels.rows_per_page'),
        }"
        :mobile-breakpoint="0"
        :sort-by.sync="sortBy"
        @update:sort-by="sortData"
        @update:page="handlePageChange"
        must-sort
      >
        <template v-slot:no-data>
          <div class="px-4">{{ $t("labels.no_data") }}</div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import CommonFilter from "../components/CommonFilter";
import APIServices from "../services/api-services";
import FunctionsUtilities from "@/utilities/functions-utility";
export default {
  name: "Stock",
  components: {
    CommonFilter,
  },
  data() {
    return {
      filterList: ["article", "manufacturer", "family"],
      moment,
      validations: [],
      page: 0,
      max_record: 20,
      tableItemId: null,
      tableItemGroup: 0,
      statisticsData: [],
      selected: [],
      firstItem: null,
      itemCount: 0,
      classSet: null,
      sortBy: null,
      type: "stock",
      headersMain: [
        { text: this.$t("labels.article"), value: "producto" },
        {
          text: this.$t("labels.manufacturer"),
          value: "fabricant",
        },
        { text: this.$t("labels.family"), value: "familia" },
        {
          text: this.$t("labels.item_description"),
          value: "nom_prod",
          sortable: true,
        },
        { text: this.$t("labels.un_embalatge"), value: "uniemba" },
        {
          text: this.$t("labels.unipes"),
          value: "unipes",
          sort: FunctionsUtilities.sortValueOnly,
        },
        { text: this.$t("labels.current_stock"), value: "stock" },
      ],
    };
  },
  mounted() {
    this.setFilterRecords();
  },
  methods: {
    filterData(data) {
      this.statisticsData = [];
      this.selected = [];
      const filter_data = data;
      APIServices.getStocks(filter_data, this.page, this.max_record).then(
        (data) => {
          if (data.length) {
            this.statisticsData = data;
            this.updateStoreFilterData();
          } else {
            this.statisticsData = [];
            this.updateStoreFilterData();
          }
        },
        () => {}
      );
    },
    getItemClass(itemId) {
      if (itemId != this.tableItemId) {
        this.tableItemGroup = !this.tableItemGroup;
      }
      this.tableItemId = itemId;
      return this.tableItemGroup == 1 ? "group-1" : "group-2";
    },
    sortData() {
      if (this.sortBy == "fabricant" || this.sortBy === undefined) {
        this.tableItemGroup = 0;
        this.statisticsData.forEach((el) => {
          el.item_group = this.getItemClass(el.id_familia);
        });
      } else {
        this.statisticsData.forEach((el) => {
          el.item_group = null;
        });
      }
    },
    clearData() {
      this.statisticsData = [];
      this.updateStoreFilterData();
    },
    changeStatus(event, item) {
      if (event) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    },
    setFilterRecords() {
      this.statisticsData = FunctionsUtilities.getSelectedRecords(
        "filter_value_records",
        this.type
      );
    },
    updateStoreFilterData() {
      FunctionsUtilities.storeFilterData(
        "filter_value_records",
        this.type,
        this.statisticsData
      );
    },
    handlePageChange() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
