<template>
  <v-app>
    <AppBar v-if="!$route.meta.fullpage" />
    <Drawer v-if="!$route.meta.fullpage" />
    <v-main :class="this.$route.meta.class">
      <div
        :class="
          !$route.meta.fullpage
            ? $vuetify.breakpoint.mdAndDown
              ? 'py-8'
              : 'px-8 py-8'
            : 'fill-height'
        "
      >
        <Breadcrumb v-if="!$route.meta.fullpage" />
        <router-view />
      </div>
    </v-main>
    <v-overlay :value="overlay" z-index="500">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import Drawer from "@/components/Drawer.vue";
import AppBar from "@/components/AppBar.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  name: "App",
  components: {
    Drawer,
    AppBar,
    Breadcrumb,
  },

  data: () => ({
    //
  }),
  watch: {
    notifications(payload) {
      if (payload && payload.length) {
        payload.forEach((notification) => {
          this.$toast.open({
            message: notification.message,
            type: notification.type,
            duration: this.snackbarTimeout,
          });
        });
        setTimeout(() => {
          this.$store.commit("common/REMOVE_NOTIFICATIONS");
        }, this.snackbarTimeout);
      }
    },
  },
  computed: {
    overlay() {
      return this.$store.state.common.pendingRequestsCount > 0;
    },
    notifications() {
      return this.$store.state.common.notifications;
    },
    checkRoute() {
      if (this.$route.name === "Login") {
        return false;
      }
      return true;
    },
  },
};
</script>
