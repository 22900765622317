const user = JSON.parse(localStorage.getItem("user"));
export default {
  user: user ? user : null,
  loggedIn: user ? true : false,
  configuration: user
    ? JSON.parse(localStorage.getItem("configuration"))
    : null,
  companies: user ? JSON.parse(localStorage.getItem("companies")) : [],
  agent: user ? JSON.parse(localStorage.getItem("user_data")).agent : null,
  company: user
    ? JSON.parse(localStorage.getItem("user_data")).company_id
    : null,
  domain: user ? localStorage.getItem("domain") : null,
};
