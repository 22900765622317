import APIService from "../../../services/api-services";

export default {
  getCities({ commit }, payload) {
    return APIService.getCities(payload).then(
      (data) => {
        commit("setCities", data);
        commit("setLastUpdateTime", "cities");
        return Promise.resolve(data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  getClients({ commit }, payload) {
    return APIService.getClients(payload.key).then(
      (data) => {
        if (payload.key) {
          payload["result"] = data;
          commit("setClientFilter", payload);
        } else {
          commit("setClients", data);
        }

        commit("setLastUpdateTime", "clients");
        return Promise.resolve(data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  getProductStatistics({ commit }, payload) {
    return APIService.getProductStatistics(payload).then(
      (data) => {
        commit("setStaticstics", data);
        return Promise.resolve(data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  getPaymentExpireData({ commit }, payload) {
    return APIService.getPaymentExpireData(payload).then(
      (data) => {
        commit("setStaticstics", data);
        return Promise.resolve(data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  getManufactures({ commit }, payload) {
    return APIService.getManufactures(payload).then(
      (data) => {
        commit("setManufactures", data);
        commit("setLastUpdateTime", "manufactures");
        return Promise.resolve(data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  getFamilies({ commit }, payload) {
    return APIService.getFamilies(payload).then(
      (data) => {
        commit("setFamilies", data);
        commit("setLastUpdateTime", "families");
        return Promise.resolve(data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  getArticles({ commit }, payload) {
    return APIService.getArticles(payload).then(
      (data) => {
        commit("setArticles", data);
        commit("setLastUpdateTime", "articles");
        return Promise.resolve(data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
};
