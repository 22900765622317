import axios from "axios";
import { baseURL } from "../config/settings";
import router from "../router";
import store from "../store";
import notificationsUtility from "../utilities/notifications-utility";
import { i18n } from "../locale/i18n";

export const HTTP = axios.create({
  baseURL: baseURL,
});
const http_url = JSON.parse(localStorage.getItem("configuration"))
  ? JSON.parse(localStorage.getItem("configuration"))
  : null;
export const HTTP_SERVER = axios.create({
  baseURL: http_url ? "http://" + http_url.ip + ":" + http_url.port : null,
});

HTTP.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: "application/json",
    };
    store.commit("common/ADD_REQUEST");
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  (response) => {
    store.commit("common/REMOVE_REQUEST");

    return response;
  },
  (error) => {
    store.commit("common/REMOVE_REQUEST");
    if (
      error.response.data.message === "Unauthenticated." ||
      error.response.status === 401
    ) {
      if (store.state.auth.loggedIn) {
        notificationsUtility.pushNotifications([
          { message: i18n.t("validations.login_required"), type: "error" },
        ]);
      }

      localStorage.removeItem("user");
      localStorage.removeItem("token");
      store.commit("auth/loginFailure");
      router.push(`login`);
    } else {
      const message = error.response.data.message
        ? error.response.data.message
        : i18n.t("validations.server_error");
      notificationsUtility.pushNotifications([
        { message: message, type: "error" },
      ]);
    }

    return Promise.reject(error);
  }
);
HTTP_SERVER.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    store.commit("common/ADD_REQUEST");
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

HTTP_SERVER.interceptors.response.use(
  (response) => {
    store.commit("common/REMOVE_REQUEST");
    return response;
  },
  (error) => {
    store.commit("common/REMOVE_REQUEST");
    if (
      error.response.data.message === "Unauthenticated." ||
      error.response.status === 401
    ) {
      if (store.state.auth.loggedIn) {
        notificationsUtility.pushNotifications([
          { message: "Es requereix login", type: "error" },
        ]);
      }
      store.commit("auth/loginFailure");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      router.push(`login`);
    }

    return Promise.reject(error);
  }
);
