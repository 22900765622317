import Vue from "vue";
import Vuex from "vuex";

import moduleCommon from "./modules/common/module-common";
import moduleAuth from "./modules/auth/module-auth";
import moduleApi from "./modules/api/module-api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    common: moduleCommon,
    auth: moduleAuth,
    api: moduleApi,
  },
});
