export default {
  setUserData(state, payload) {
    state.loggedIn = true;
    state.user = payload;
  },
  setConfiguration(state, payload) {
    state.configuration = payload;
  },
  setCompanies(state, payload) {
    state.companies = payload;
  },
  loginFailure(state) {
    state.loggedIn = false;
    state.user = null;
  },
  setAgent(state, payload) {
    state.agent = payload.username;
    state.company = payload.empresa;
  },
  setDomain(state, payload) {
    state.domain = payload;
  },
};
