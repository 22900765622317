import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#418700",
        primarylight1: "#f5ffec",
        secondary: "#C4C4C4",
        blackdefault: "#2d2d2d",
        greydefault: "#707070",
        greylight1: "#737373",
        greylight2: "#7D7D7D",
        greylight3: "#949494",
        greylight4: "#E8E8E8",
        whitelight1: "#F8F9FD",
        whitelight2: "#f5f5f5",
      },
    },
    options: { customProperties: true },
  },
});
