<template>
  <v-card class="section-wrap">
    <v-expansion-panels v-model="expandedPanelIndex" flat readonly>
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="">
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-filter-variant</v-icon>
            <span class="heading-title-2">{{ $t("labels.filter") }}</span>
          </div>
          <FilterActions
            v-if="$vuetify.breakpoint.mdAndDown"
            :clear-handler="clear"
            :filter-handler="filter"
          ></FilterActions>
        </v-expansion-panel-header>
        <v-form v-model="valid" ref="form">
          <v-expansion-panel-content class="pt-4">
            <v-row>
              <v-col cols="12" md="4" sm="6" xs="12">
                <div class="d-flex">
                  <v-autocomplete
                    v-model="filterValues.city"
                    :items="cities"
                    item-value="descripcion"
                    item-text="descripcion"
                    outlined
                    label="Població"
                    clearable
                    hide-details="auto"
                  >
                    <template v-slot:no-data>
                      <div class="px-4">{{ $t("labels.no_data") }}</div>
                    </template>
                  </v-autocomplete>
                  <ListModal
                    :dataSet="cities"
                    :type="'city'"
                    @set-update="setCity"
                  />
                </div>
              </v-col>
              <v-col cols="12" md="4" sm="6" xs="12">
                <div class="d-flex">
                  <v-autocomplete
                    v-model="filterValues.client"
                    :items="clients"
                    :rules="validations"
                    item-text="descripcion"
                    item-value="cdg"
                    outlined
                    label="Client"
                    clearable
                    hide-details="auto"
                  >
                    <template v-slot:no-data>
                      <div class="px-4">{{ $t("labels.no_data") }}</div>
                    </template>
                  </v-autocomplete>
                  <ListModal
                    :dataSet="clients"
                    :type="'client'"
                    @set-update="setClient"
                  />
                </div>
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="12"
                v-if="type != 'paymentExpireData'"
              >
                <v-text-field
                  v-model="filterValues.description"
                  label="Descripció Article"
                  outlined
                  hide-details="auto"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <FilterActions
              v-if="$vuetify.breakpoint.lgAndUp"
              fliter-actions-class="mt-7 mb-3"
              :clear-handler="clear"
              :filter-handler="filter"
            ></FilterActions>
          </v-expansion-panel-content>
        </v-form>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import FunctionsUtilities from "@/utilities/functions-utility";
import ListModal from "@/components/ListModal.vue";
import FilterActions from "@/components/FilterActions.vue";
export default {
  name: "ProductsFilter",
  props: {
    validations: Array,
    type: String,
  },
  components: {
    ListModal,
    FilterActions,
  },

  data() {
    return {
      valid: true,
      cities: [],
      clients: [],
      max_record: 100,
      page: 0,
      filterValues: {
        city: null,
        client: null,
        description: null,
      },
      expandedPanelIndex: 0,
      clientValue: "",
      selectedClientData: [],
    };
  },
  mounted() {
    this.isFilterUpdated("cities");
    this.isFilterUpdated("clients");
    this.setSelectedFilters("all");
  },
  methods: {
    getItemText(item) {
      return `${item.cdg} - ${item.descripcion} (${item.codpostal})${item.direccion}`;
    },
    getCities() {
      this.$store
        .dispatch("api/getCities")
        .then((response) => {
          this.cities = response;
        })
        .catch(() => {});
    },
    getClients() {
      this.$store
        .dispatch("api/getClients", {
          type: this.type,
          key: this.filterValues.city,
        })
        .then((response) => {
          this.clients = response.length ? response : [];
        })
        .then(() => {
          this.setSelectedFilters("client");
        })
        .catch(() => {});
    },
    filter() {
      if (this.$refs.form.validate()) {
        this.$emit("update-filter", this.filterValues);
      }
    },
    setClient(data) {
      this.filterValues.client = data.cdg;
    },
    setCity(data) {
      this.filterValues.city = data.descripcion;
    },
    clear() {
      this.filterValues = Object.assign({}, this.$options.data().filterValues);
      this.$refs.form.reset();
      this.$emit("clear-data");
    },
    setSelectedFilters($typeKey) {
      const selectedFilterValues =
        FunctionsUtilities.getStoreData("filter_values");
      if (selectedFilterValues[this.type]) {
        const details = selectedFilterValues[this.type];
        Object.keys(details).forEach((value) => {
          if ($typeKey == "client" && value == "client") {
            this.filterValues[value] = this.clientValue;
          } else if ($typeKey == "all" && value == "client") {
            this.clientValue = details[value];
          } else {
            this.filterValues[value] = details[value];
          }
        });
      }
      this.setDefaultClient();
    },
    setDefaultClient() {
      const clientId = FunctionsUtilities.setDefaultClient(this.type);
      if (clientId) {
        var self = this;
        setTimeout(function () {
          self.filterValues.client = clientId;
        }, 4000);
      }
    },
    isFilterUpdated(key) {
      const result = FunctionsUtilities.checkFilterDataUpdated(key);
      if (result) {
        this.setClientFilter(key);
      } else {
        const keyName = `get${key.charAt(0).toUpperCase()}${key.slice(1)}`;
        this[keyName]();
      }
    },
    setClientFilter(key) {
      if (key == "clients" && this.filterValues.city) {
        //get selected city wise client records
        const clientCityFilter = this.$store.state.api.clientCityFilter;
        if (
          clientCityFilter[this.type] &&
          clientCityFilter[this.type].key == this.filterValues.city
        ) {
          const dataList = clientCityFilter[this.type].result;
          this.setFilterStoreData(key, "client", dataList);
        } else {
          const keyName = `get${key.charAt(0).toUpperCase()}${key.slice(1)}`;
          this[keyName]();
        }
      } else {
        //get all client records
        const dataList = this.$store.state.api[key];
        this.setFilterStoreData(key, "client", dataList);
      }
    },
    setFilterStoreData(key, typeName, dataList) {
      this[key] = dataList;
      setTimeout(() => {
        if (key == "clients") this.setSelectedFilters(typeName);
      }, 1000);
    },
  },
  watch: {
    "filterValues.city"() {
      this.filterValues.client = null;
      this.page = 0;
      this.isFilterUpdated("clients");
    },
    filterValues: {
      handler: function (data) {
        FunctionsUtilities.storeFilterData("filter_values", this.type, data);
      },
      deep: true,
    },
    "filterValues.client"() {
      this.selectedClientData = this.clients.find(
        (row) => row.cdg === this.filterValues.client
      );
      this.$emit("change-client-filter", this.selectedClientData);
    },
  },
};
</script>
