export default {
  drawer: null,
  mini:
    localStorage.getItem("drawer_mini") &&
    localStorage.getItem("drawer_mini") === "true"
      ? true
      : false,
  notifications: [],
  pendingRequestsCount: 0,
};
