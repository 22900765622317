export default {
  setCities(state, payload) {
    state.cities = payload;
  },
  setClients(state, payload) {
    state.clients = payload;
  },
  setStaticstics(state, payload) {
    state.statistics = payload;
  },
  setManufactures(state, payload) {
    state.manufactures = payload;
  },
  setFamilies(state, payload) {
    state.families = payload;
  },
  setArticles(state, payload) {
    state.articles = payload;
  },
  setLastUpdateTime(state, payload) {
    state.lastUpdate[payload] = new Date().toISOString().substr(0, 10);
  },
  setClientFilter(state, payload) {
    state.clientCityFilter[payload.type] = payload;
  },
  resetState(state) {
    state.cities = [];
    state.clients = [];
    state.statistics = [];
    state.manufactures = [];
    state.lastUpdate = {};
    state.clientCityFilter = {};
  },
};
