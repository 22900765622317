import AuthService from "../../../services/auth-service";
export default {
  login({ commit }, payload) {
    return AuthService.login(payload).then(
      (user) => {
        if (user.token) commit("setUserData", user);
        return Promise.resolve(user);
      },
      (error) => {
        // commit("loginFailure");
        return Promise.reject(error);
      }
    );
  },

  getConfiguration({ commit }, payload) {
    return AuthService.getConfiguration(payload).then(
      (data) => {
        commit("setConfiguration", data);
        return Promise.resolve(data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },

  getCompanies({ commit }, payload) {
    return AuthService.getCompanies(payload).then(
      (data) => {
        commit("setCompanies", data);
        return Promise.resolve(data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
};
