<template>
  <v-card class="section-wrap">
    <v-expansion-panels v-model="expandedPanelIndex" flat readonly>
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="">
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-filter-variant</v-icon>
            <span class="heading-title-2">{{ $t("labels.filter") }}</span>
          </div>
          <FilterActions
            v-if="$vuetify.breakpoint.mdAndDown"
            :clear-handler="clear"
            :filter-handler="filterData"
          ></FilterActions>
        </v-expansion-panel-header>
        <v-form v-model="valid" ref="form">
          <v-expansion-panel-content class="pt-4">
            <v-row>
              <v-col cols="12" md="4" sm="12" v-if="checkFilter('article')">
                <v-text-field
                  v-model="filterValues.article"
                  :label="$t('labels.article')"
                  outlined
                  hide-details="auto"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="6"
                xs="12"
                v-if="checkFilter('manufacturer')"
              >
                <div class="d-flex">
                  <v-autocomplete
                    v-model="filterValues.manufacturer"
                    :items="manufactures"
                    item-value="cdg"
                    item-text="descripcion"
                    outlined
                    :label="$t('labels.manufacturer')"
                    clearable
                    hide-details="auto"
                  >
                    <template v-slot:no-data>
                      <div class="px-4">{{ $t("labels.no_data") }}</div>
                    </template>
                  </v-autocomplete>
                  <CommonListModal
                    :dataSet="manufactures"
                    :label-name="$t('labels.manufacturer')"
                    dataType="manufacturer"
                    @set-update="setSelectData"
                  />
                </div>
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="6"
                xs="12"
                v-if="checkFilter('family')"
              >
                <div class="d-flex">
                  <v-autocomplete
                    v-model="filterValues.family"
                    :items="families"
                    item-text="descripcion"
                    item-value="cdg"
                    outlined
                    :label="$t('labels.family')"
                    clearable
                    hide-details="auto"
                  >
                    <template v-slot:no-data>
                      <div class="px-4">{{ $t("labels.no_data") }}</div>
                    </template>
                  </v-autocomplete>
                  <CommonListModal
                    :dataSet="families"
                    :label-name="$t('labels.family')"
                    dataType="families"
                    @set-update="setSelectData"
                  />
                </div>
              </v-col>
            </v-row>
            <FilterActions
              v-if="$vuetify.breakpoint.lgAndUp"
              fliter-actions-class="mt-7 mb-3"
              :clear-handler="clear"
              :filter-handler="filterData"
            ></FilterActions>
          </v-expansion-panel-content>
        </v-form>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import CommonListModal from "@/components/CommonListModal.vue";
import FunctionsUtilities from "@/utilities/functions-utility";
import FilterActions from "@/components/FilterActions.vue";
export default {
  name: "CommonFilter",
  props: {
    validations: Array,
    filterList: Array,
    type: String,
  },
  components: {
    CommonListModal,
    FilterActions,
  },

  data() {
    return {
      valid: true,
      families: [],
      manufactures: [],
      max_record: 100,
      page: 0,
      filterValues: {
        article: null,
        manufacturer: null,
        family: null,
      },
      expandedPanelIndex: 0,
    };
  },
  watch: {
    filterValues: {
      handler: function (data) {
        FunctionsUtilities.storeFilterData("filter_values", this.type, data);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.checkFilter("manufacturer")) this.isFilterUpdated("manufactures");
    if (this.checkFilter("family")) this.isFilterUpdated("families");
    this.setSelectedFilters("all");
  },
  methods: {
    getManufactures() {
      this.$store
        .dispatch("api/getManufactures")
        .then((response) => {
          this.manufactures = response;
        })
        .catch(() => {});
    },
    getFamilies() {
      this.$store
        .dispatch("api/getFamilies")
        .then((response) => {
          this.families = response.length ? response : [];
        })
        .catch(() => {});
    },
    filterData() {
      if (this.$refs.form.validate()) {
        this.$emit("update-filter", this.filterValues);
      }
    },
    setSelectData(data) {
      switch (data.type) {
        case "manufacturer":
          this.filterValues.manufacturer = data.details["cdg"];
          break;
        case "families":
          this.filterValues.family = data.details["cdg"];
          break;
        default:
      }
    },
    clear() {
      this.filterValues = Object.assign({}, this.$options.data().filterValues);
      this.$refs.form.reset();
      this.$emit("clear-data");
    },
    checkFilter(value) {
      return this.filterList.includes(value);
    },
    setSelectedFilters($dataType) {
      const selectedFilterValues = JSON.parse(
        localStorage.getItem("filter_values")
      );
      if (selectedFilterValues[this.type]) {
        const details = selectedFilterValues[this.type];
        Object.keys(details).forEach((value) => {
          if ($dataType == "all") {
            this.filterValues[value] = details[value];
          }
        });
      }
    },
    isFilterUpdated(key) {
      const result = FunctionsUtilities.checkFilterDataUpdated(key);
      if (result) {
        this[key] = this.$store.state.api[key];
      } else {
        const keyName = `get${key.charAt(0).toUpperCase()}${key.slice(1)}`;
        this[keyName]();
      }
    },
  },
};
</script>
